<template>

  <div>
    <b-row
      class="content-header"
    >
      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Stock Adjustment
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-form @submit.prevent="submitStockAdjustForm">
      <validation-observer
        ref="inventoryItemCreateForm"
        #default="{invalid}"
      >
        <b-card
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="RepeatIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Stock Adjustment List
            </h3>
          </template>
          <b-row>
            <b-col
              lg="3"
              md="3"
              sm="12"
            >
              <b-form-group
                label="Store*"
                label-for="store"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Store"
                  vid="store"
                  rules="required"
                >
                  <v-select
                    id="store"
                    v-model="store"
                    label="name"
                    placeholder="Select a store"
                    :options="permittedDataOptions"
                    :reduce="name => name._id"
                    :clearable="false"
                    @input="checkStoreExistsInItems()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="storeValidation"
                    class="text-danger"
                  >
                    {{ storeError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <hr>
          <h4 class="stock-adjustment-title">
            Select inventory item(s) to adjust
          </h4>
          <b-row
            v-if="!isMobile()"
            style="margin-bottom: 6px"
          >
            <b-col
              lg="8"
              md="8"
              sm="3"
            >Item(s)
            </b-col>
            <b-col
              lg="3"
              md="3"
              sm="3"
              class="text-center"
            >Quantity</b-col>
          </b-row>
          <div v-if="stockItems.length > 0">
            <b-row
              v-for="(stockItem, stockIndex) in stockItems"
              :id="'stockItems' + stockItem.item"
              :key="stockIndex"
            >
              <b-col
                lg="8"
                md="8"
                sm="12"
              >
                <span v-if="isMobile()">Item(s)</span>
                <div
                  class="d-flex justify-content-between"
                >
                  <b-form-group
                    :label-for="'itemName'+ stockIndex"
                    style="width: 100%"
                  >
                    <b-form-input
                      :id="'itemName'+ stockIndex"
                      v-model="stockItem.name"
                      placeholder="Item name"
                      disabled
                      class="disabled-clear"
                    />
                  </b-form-group>
                  <b-img
                    class="item-img-block"
                    :src="stockItem.image ? stockItem.image : defaultImage"
                  />
                </div>
              </b-col>
              <b-col
                :lg="isMobile() ? 4 : 3"
                :md="isMobile() ? 4 : 3"
                sm="12"
              >
                <b-row>
                  <b-col
                    cols="3"
                  >
                    <div
                      :class="{'stock-mobile-style':isMobile(), 'stock-default-style':!isMobile()}"
                    >
                      {{ stockItem.stock }} </div>
                  </b-col>
                  <b-col
                    cols="1"
                  >
                    <div
                      :class="{'stock-mobile-style':isMobile(), 'stock-default-style':!isMobile()}"
                    >
                      <feather-icon
                        icon="ArrowRightIcon"
                      />
                    </div>
                  </b-col>
                  <b-col
                    cols="7"
                  >
                    <span
                      v-if="isMobile()"
                      class="d-block"
                    >
                      Quantity
                    </span>
                    <div class="d-flex justify-content-between">
                      <validation-provider
                        #default="{ errors }"
                        name="Quantity"
                        :vid="`quantity_${stockIndex}`"
                        rules="required|min_value:0|numeric"
                        style="width: 100%"
                      >
                        <b-form-group
                          :label-for="`quantity_${stockIndex}`"
                          :class="{ 'is-invalid': errors.length }"
                        >
                          <b-form-input
                            :id="`quantity_${stockIndex}`"
                            v-model="stockItem.quantity"
                            name="quantity"
                            placeholder="0"
                            @keypress="validateNumberInput"
                            @paste="validateNumberPaste"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                      <b-button
                        v-if="isMobile()"
                        variant="flat-primary"
                        class=""
                        type="button"
                        @click="removeStockItem(stockIndex)"
                      >
                        <feather-icon
                          icon="Trash2Icon"
                          class="trash-icon"
                        />
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                md="1"
                lg="1"
                sm="12"
              >
                <b-button
                  v-if="!isMobile()"
                  variant="flat-primary"
                  class=""
                  type="button"
                  @click="removeStockItem(stockIndex)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    class="trash-icon"
                  />
                </b-button>
              </b-col>
            </b-row>
          </div>
          <b-row>
            <b-col
              lg="8"
              md="8"
              sm="12"
            >
              <span v-if="isMobile()">Item(s)</span>
              <div
                class="d-flex justify-content-between search-bar"
              >
                <b-form-input
                  id="search_name"
                  v-model="searchInput"
                  label="Item(s)"
                  placeholder="Search item"
                  autocomplete="off"
                  @keyup="submitSearchInput()"
                />
                <b-img
                  class="item-img-block"
                  :src="defaultImage"
                />
              </div>
              <div v-show="searchInputResult">
                <div
                  class="search-results mt-50"
                >
                  <div>
                    <b-list-group
                      v-if="storeItemSearchInputData.length"
                      flush
                    >
                      <span class="found-text-block">Inventory items found in this store</span>
                      <b-list-group-item
                        v-for="(storeItem, sIndex) in storeItemSearchInputData"
                        :key="sIndex"
                        class="cursor-pointer"
                        @click="addSearchInputStoreItem(sIndex, storeItem.item._id)"
                      >
                        <div class="d-flex">
                          <b-img
                            class="item-img-block"
                            :src="storeItem.item.image ? storeItem.item.image : defaultImage"
                          />
                          <div
                            class="search-item-text-block"
                          >
                            <h6>{{ storeItem.item.name }}</h6>
                            <small class="text-muted">{{ storeItem.item.sku }}</small>
                          </div>
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                </div>
              </div>
              <b-card
                v-show="searchResultNotFound"
                no-body
                class="mt-50"
              >
                <div class="result-not-found-block">
                  <h5>No inventory item found.</h5>
                </div>
              </b-card>
            </b-col>
            <b-col
              :lg="isMobile() ? 4 : 3"
              md="3"
              sm="12"
            >
              <b-row>
                <b-col
                  cols="3"
                />
                <b-col
                  cols="1"
                >
                  <div
                    :class="{'stock-mobile-style':isMobile(), 'stock-default-style':!isMobile()}"
                  >
                    <feather-icon
                      icon="ArrowRightIcon"
                    />
                  </div>
                </b-col>
                <b-col
                  cols="7"
                >
                  <span
                    v-if="isMobile()"
                    class="d-block"
                  >
                    Quantity
                  </span>
                  <b-form-group
                    label-for="quantity"
                    style="width: 100%"
                  >
                    <b-form-input
                      id="quantity"
                      v-model="quantity"
                      name="quantity"
                      placeholder="0"
                      @keypress="validateNumberInput"
                      @paste="validateNumberPaste"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <hr>
          <div class="d-flex justify-content-between">
            <p><span class="text-bold-black mr-75">Inventory Item(s)</span>  {{ stockItems.length ? stockItems.length : '-' }}</p>
            <p>
              <span class="text-bold-black">Total Quantity</span>
              <span
                class="ml-75"
                :class="{ 'text-success': stockAdjustQuantity >=0, 'text-danger': stockAdjustQuantity <0 }"
              >
                {{ stockAdjustQuantity }}
              </span>
            </p>
          </div>
        </b-card>
        <b-card
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Remarks
            </h3>
          </template>
          <b-row>
            <b-col md="12">
              <b-form-group
                label=""
                label-for="remarks"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Remarks"
                  vid="remarks"
                  rules="required"
                >
                  <b-form-textarea
                    id="remarks"
                    v-model="remarks"
                    :state="(errors.length > 0) ? false : null"
                    name="remarks"
                    rows="5"
                    placeholder="Any other information for the adjustment.*"
                  />
                  <small
                    v-if="errors.length > 0"
                    class="text-danger"
                  >
                    {{ remarksError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-nav class="wrap-border save-nav">
          <li><span class="ml-2">Stock adjustment by <b>{{ user.name }}</b> on {{ dateFormatWithTime(new Date()) }}</span></li>
          <li
            class="nav-item ml-auto"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              :to="{ name: 'inventory-stock-list', query: { store: storeOriginal } }"
            >
              Cancel
            </b-button>
          </li>
          <li
            class="nav-item mr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-modal.modal-stock-in-items
              type="button"
              variant="success"
              :disabled="invalid || stockItems.length < 1"
              @click="stockAdjustAllItemsPreview()"
            >
              <feather-icon
                icon="RepeatIcon"
                class="mr-50"
              />
              <span class="align-middle">Confirm</span>
            </b-button>
          </li>
        </b-nav>
      </validation-observer>
    </b-form>
    <!-- stock in items modal -->
    <b-modal
      id="modal-stock-in-items"
      ref="stock-in-items-modal"
      modal-class="status-update-modal"
      size="lg"
      centered
      no-stacking
      no-close-on-backdrop
      @ok="submitStockAdjustForm"
      @cancel="closeStockAdjustItemsModal"
    >
      <template #modal-header>
        <div class="stock-items-modal-header">
          <feather-icon
            icon="RepeatIcon"
            style="color: #00CFE8; margin-bottom: 7px;"
            size="60"
          />
          <div class="stock-in-item-modal-heading-block">
            <h4 style="color: #000000">
              Proceed with Stock Adjustment?
            </h4>
            <p>The following item(s) in <b>{{ resolveStoreName(store) }}</b> will be adjusted.</p>
          </div>
        </div>
      </template>
      <b-table-simple
        responsive
        sticky-header
        class="shadow-table"
      >
        <b-thead>
          <b-tr>
            <b-th style="height: 0px !important;">
              Item
            </b-th>
            <b-th style="height: 0px !important; text-align: right;">
              Quantity
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(stockAdjustItem, stockIndex) in stockAdjustPreviewItems"
            :key="stockIndex"
          >
            <b-td>
              <div class="d-flex">
                <b-img
                  class="mb-1 mb-sm-0 user__avatar img-preview-block"
                  :src="stockAdjustItem.image ? stockAdjustItem.image : defaultImage"
                />
                <div
                  class="ml-1"
                >
                  <span class="text-bold-black">{{ stockAdjustItem.name || '-' }}</span>
                  <br>
                  <span class="text-muted">{{ stockAdjustItem.sku || '-' }}</span>
                </div>
              </div>
            </b-td>
            <b-td class="text-nowrap">
              <div
                class="d-flex justify-content-around"
                style="margin-top: 0.7rem !important;"
              >
                <div
                  style="text-align: right !important;"
                >
                  {{ stockAdjustItem.stock }}
                </div>
                <div
                  style="text-align: right !important;"
                >
                  <feather-icon
                    icon="ArrowRightIcon"
                  />
                </div>
                <div
                  style="color: #00CFE8; text-align: left !important;"
                >
                  {{ stockAdjustItem.quantity }}
                </div>
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <div class="d-flex justify-content-between">
        <p><span class="text-bold-black mr-75">Inventory Item(s)</span>  {{ stockItems.length }}</p>
        <p>
          <span class="text-bold-black">Total Quantity</span>
          <span
            class="ml-75"
            :class="{ 'text-success': stockAdjustQuantity >=0, 'text-danger': stockAdjustQuantity <0 }"
          >
            {{ stockAdjustQuantity }}
          </span>
        </p>
      </div>
      <hr class="mt-0">
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          No, go back
        </b-button>
        <b-button
          variant="primary"
          type="button"
          @click="ok()"
        >
          <span class="align-middle">Yes, proceed</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ stock in items modal -->
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BForm, BButton, BNav, BImg, BListGroup, BListGroupItem, BTableSimple, BThead, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store/index'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ToastificationContentCustom from './ToastificationContentCustom.vue'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BNav,
    BImg,
    BListGroup,
    BListGroupItem,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentCustom,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/placeholder/placeholder_image.png'),
      saveStockAdjustment: false,
      searchInput: '',
      storeOriginal: localStorage.getItem('storeVal'),
      store: localStorage.getItem('storeVal'),
      storeValidation: false,
      storeError: 'Store is required',
      user: store.state.auth.userData,
      quantity: '',
      remarks: '',
      remarksError: 'Please state a reason(s) for this stock adjustment.',
      remarksValidation: false,
      storeDataOptions: [],
      permittedDataOptions: [],
      stockItems: [],
      storeItemData: [],
      masterItemData: [],
      selectStockItem: JSON.parse(localStorage.getItem('items')) || [],
      storeItemSearchInputData: [],
      masterItemSearchInputData: [],
      searchInputResult: false,
      searchResultNotFound: false,
      stockAdjustPreviewItems: [],
      // validation rules
      required,
    }
  },
  computed: {
    stockAdjustQuantity() {
      const allStockItems = this.stockItems.length
      if (allStockItems) {
        let initStockQuantity = 0
        this.stockItems.forEach(item => {
          if (item.quantity !== '') {
            if (item.quantity > item.stock) {
              initStockQuantity -= -(item.quantity - item.stock)
            } else {
              initStockQuantity -= (item.stock - item.quantity)
            }
          }
        })
        if (initStockQuantity >= 0) {
          return `+${initStockQuantity}`
        }
        return initStockQuantity
      }
      return 0
    },
  },
  beforeMount() {
    this.$http.get('inventory/category/respond-with/name-and-id')
      .then(response => {
        this.categories = response.data.categories ?? []

        this.category = this.categories[0]._id ?? ''
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  beforeRouteLeave(to, from, next) {
    if (!this.saveStockAdjustment) {
      this.$swal({
        title: 'Unsaved Changes - Leave?',
        html: 'You have unsaved changes. Are you sure you want to leave this page?',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: "Yes, I'm Sure",
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-outline-primary no-focus-bg ml-1',
          cancelButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          localStorage.removeItem('storeVal')
          localStorage.removeItem('items')
          if (result.value) {
            next()
          } else {
            next(false)
          }
        })
    } else {
      next()
    }
  },
  created() {
    this.$http.get('inventory/stock/respond-with/store-and-item')
      .then(response => {
        this.storeDataOptions = response.data.stores
        this.storeItemData = response.data.storeItems
        const storeStockItems = response.data.storeItems
        if (this.selectStockItem.length) {
          this.selectStockItem.forEach(selectedItem => {
            const item = storeStockItems.find(itemObj => itemObj.item._id === selectedItem.item && itemObj.store === this.store)
            if (item) {
              this.stockItems.push({
                item: item.item._id,
                store: item.store,
                name: item.itemName,
                image: item.image,
                sku: item.sku,
                quantity: this.quantity,
                stock: item.stock,
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `${selectedItem.itemName} was not found.` ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }
          })
        }

        this.permittedDataOptions = []
        this.storeDataOptions.forEach(iStore => {
          const filteredArray = iStore.roles.filter(o1 => this.user.roles.some(o2 => o1._id === o2._id))
          const foundPermission = filteredArray.filter(o => o.permissions.find(i => i.action === 'stock-adjustment'))
          if (foundPermission.length) {
            this.permittedDataOptions.push(iStore)
          }
        })
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    validateNumberInput(event) {
      if (event.key && !/^-?\d*[.]?\d*$/.test(event.key)) {
        event.preventDefault()
      }
    },
    validateNumberPaste(event) {
      const text = (event.originalEvent || event).clipboardData.getData('text/plain')
      if (text && !/^-?\d*[.]?\d*$/.test(text)) {
        event.preventDefault()
      }
    },
    removeStockItem(stockIndex) {
      this.stockItems.splice(stockIndex, 1)
    },
    checkStoreExistsInItems() {
      this.stockItems = []
    },
    submitSearchInput() {
      this.storeItemSearchInputData = this.storeItemData.filter(item => item.store === this.store && item.item.deletedAt === null && (item.itemName.toLowerCase().includes(this.searchInput.toLowerCase()) || item.sku.toLowerCase().includes(this.searchInput.toLowerCase())))
      if (this.searchInput !== '') {
        this.searchInputResult = true
      } else {
        this.searchInputResult = false
      }
      if (!this.storeItemSearchInputData.length) {
        this.searchResultNotFound = true
      } else {
        this.searchResultNotFound = false
      }
    },
    addSearchInputStoreItem(index, id) {
      if (this.storeItemData.length) {
        const item = this.storeItemData.find(storeItemObj => storeItemObj.item._id === id && storeItemObj.store === this.store)
        if (item) {
          if (this.stockItems.length) {
            const foundItemId = this.stockItems.find(obj => obj.item === item.item._id)
            if (foundItemId) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'This item is already added!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            } else {
              this.stockItems.push({
                item: item.item._id,
                store: item.store,
                name: item.itemName,
                image: item.image,
                sku: item.sku,
                quantity: this.quantity,
                stock: item.stock,
              })
              this.searchInput = ''
              this.quantity = ''
              this.searchInputResult = false
              this.storeItemSearchInputData.splice(index, 1)
            }
          } else {
            this.stockItems.push({
              item: item.item._id,
              store: item.store,
              name: item.itemName,
              image: item.image,
              sku: item.sku,
              quantity: this.quantity,
              stock: item.stock,
            })
            this.quantity = ''
            this.searchInput = ''
            this.quantity = ''
            this.searchInputResult = false
            this.storeItemSearchInputData.splice(index, 1)
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'This item is not found on this store.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    closeStockAdjustItemsModal() {
      this.$root.$emit('bv::hide::modal', 'modal-stock-in-items', '')
      this.stockAdjustPreviewItems = []
    },
    resolveStoreName(storeId) {
      const storeData = this.storeDataOptions.find(s => s._id === storeId)
      if (storeData) {
        return storeData.name
      }
      return ''
    },
    stockAdjustAllItemsPreview() {
      this.stockItems.forEach(stockItem => {
        this.stockAdjustPreviewItems.push({
          item: stockItem.item,
          name: stockItem.name,
          image: stockItem.image,
          sku: stockItem.sku,
          store: stockItem.store,
          quantity: stockItem.quantity,
          stock: stockItem.stock,
        })
      })
    },
    submitStockAdjustForm() {
      const formData = new FormData()
      formData.append('items', JSON.stringify(this.stockItems))
      formData.append('store', this.store)
      formData.append('totalItems', this.stockItems.length)
      formData.append('totalQuantity', this.stockAdjustQuantity)
      formData.append('remarks', this.remarks)
      this.$http.post('inventory/stock/stock-adjustment', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(() => {
          localStorage.removeItem('storeVal')
          localStorage.setItem('storeOperation', 'stock-adjustment')
          localStorage.setItem('items', JSON.stringify(this.stockItems))
          let storeList = ''
          const found = this.storeDataOptions.find(obj => obj._id === this.store)
          if (found) {
            if (storeList === '') {
              storeList += `${found.name} (${found.department.name})`
            } else {
              storeList += `${found.name} (${found.department.name})`
            }
          }
          const toastMessage = `The quantity of <span class="text-bold-black">${this.stockItems.length} inventory item(s)</span> in <span class="text-bold-black">${storeList}</span> has been adjusted.`
          this.$toast({
            component: ToastificationContentCustom,
            props: {
              title: 'Stock Adjustment Successful',
              text: toastMessage,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.saveStockAdjustment = true
          this.$router.push({ name: 'inventory-stock-list', query: { store: this.store } })
        })
        .catch(error => {
          this.stockAdjustPreviewItems = []
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style scoped>
  .item-img-block{
    box-sizing: border-box;
    width: 38px;
    height: 38px;
    border: 1px solid #D2DCEA;
    border-radius: 5px;
    margin-left: 2px;
  }
  .trash-icon{
     cursor: pointer;
  }
  .search-bar{
     position: relative;
  }
  .search-results{
    width: 97.5%;
    position: absolute;
    top: 5;
    z-index: 1;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), rgba(0, 0, 0, 0.0001);
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    overflow-y: auto;
    max-height: 340px;
  }
  .result-not-found-block{
    width: 100%;
    position: absolute;
    top: 5;
    left: 0;
    z-index: 1;
    text-align: center;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), rgba(0, 0, 0, 0.0001);
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 20px 0;
  }
  .found-text-block{
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #333333;
      padding: 15px 30px 5px 22px;
  }
  .store-options{
    display: flex;
    justify-content: space-between;
  }
  .add-more-store-btn {
    margin-left: -14px;
    margin-top: -13px;
  }
  /* stock in items modal */
  .stock-items-modal-header{
    margin: 20px auto 0px auto;
    text-align: center;
  }
  .img-preview-block{
      box-sizing: border-box;
      width: 38px;
      height: 38px;
      border: 1px solid #D2DCEA;
      border-radius: 5px;
  }
  /* ./ stock in items modal */
  .stock-adjustment-title{
    font-weight: 600;
    font-size: 14px;
    color: #333333;
  }
  .stock-default-style{
     margin-top: 0.7rem !important;
     text-align: right;
  }
  .stock-mobile-style{
    margin-top: 2.7rem !important;
    text-align: center;
  }
  @media (max-width: 1440px) {
    .stock-mobile-style{
      margin-top: 2.1rem !important;
      text-align: center;
   }
  }
</style>
